import React from 'react'

import { CheckoutLayout, CheckoutRouter } from '../../components'

const CheckoutTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <CheckoutLayout lang={lang} switcher={page}>
      <CheckoutRouter lang={lang} />
    </CheckoutLayout>
  )
}

export default CheckoutTemplate
